<template>
  <div>
    <template v-if="numPages">
      <pdf ref="pdf" v-for="i in numPages" :key="i" :src="url" :page="i"></pdf>
    </template>
    <div v-else-if="!numPages">
      <el-link type="primary" :href="url" target="_blank"
        >点击预览文件</el-link
      >
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: { pdf },
  props: {
    url: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      numPages: null, // pdf 总页数
    };
  },
  mounted() {
    this.getNumPages();
  },
  methods: {
    getNumPages() {
      const url = this.url;
      const type = url.substr(url.length - 4, url.length);
      if (type === ".pdf") {
        let src = pdf.createLoadingTask(url);
        src.promise
          .then((pdf) => {
            this.numPages = pdf.numPages;
          })
          .catch((err) => {
            console.error("pdf 加载失败", err);
          });
      }
    },
  },
};
</script>

<style>
</style>